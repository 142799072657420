<template>
  <div class="container">
    <vs-button @click="showNewGroup = true">
      Nuevo Grupo
    </vs-button>
    <h2>Mis Grupos</h2>
    <vs-table>
      <template #thead>
        <vs-tr>
          <vs-th>
            Nombre del grupo
          </vs-th>
          <vs-th>
            Nº de usuarios
          </vs-th>
        </vs-tr>
      </template>
      <template #tbody>
        <vs-tr :key="i" v-for="(tr, i) in groups" :data="tr">
          <vs-td
            edit
            @click="(edit = tr), (editProp = 'name'), (editActive = true)"
          >
            {{ tr.name }}
          </vs-td>
          <vs-td
            edit
            @click="(edit = tr), (editProp = 'users'), (editActive = true)"
          >
            {{ tr.members.length }}
          </vs-td>
        </vs-tr>
      </template>
      <template #notFound>
        No hay ningún grupo creado
      </template>
    </vs-table>

    <vs-dialog v-model="editActive">
      <template #header>Editar grupo:</template>
      <vs-input
        @keypress.enter="editActive = false"
        v-if="editProp == 'name'"
        v-model="edit.name"
      />
      <vs-select
        @change="editActive = false"
        block
        v-if="editProp == 'users'"
        multi
        placeholder="Select"
        v-model="edit[editProp]"
      >
        <vs-option
          v-for="(member, mInd) in edit.members"
          :key="'member_' + mInd"
          :label="member.id"
          :value="member.id"
        >
          {{member.id}}
        </vs-option>
      </vs-select>
    </vs-dialog>

    <vs-dialog not-center v-model="showNewGroup">
      <template #header>
        <h4 class="not-margin">Cuál es el nombre del <b>grupo</b>?</h4>
      </template>

      <div class="con-content">
        <vs-input
          v-model="groupName"
          label-placeholder="Nombre del grupo:"
        ></vs-input>
      </div>

      <template #footer>
        <div class="dialogActionBtns">
          <vs-button @click="showNewGroup = false" danger transparent>
            Cancelar
          </vs-button>
          <vs-button @click="onCreate" success>
            Crear
          </vs-button>
        </div>
      </template>
    </vs-dialog>
  </div>
</template>

<script>
import { groupMix } from "@/mixins/mixins.js";

export default {
  name: "GroupPage",
  mixins: [groupMix],
  data() {
    return {
      editActive: false,
      edit: null,
      editProp: {},
      showNewGroup: false,
      groupName: "",
      groups: [],
    };
  },
  methods: {
    async onCreate() {
      const { uid } = this.$store.state.currentUserInfo;
      const data = {
        name: this.groupName,
        members: [uid],
        created_at: new Date().toLocaleDateString(),
        created_by: uid,
      };
      await this.createGroup(data);
      this.groups.push(data);
      this.groupName = "";
      this.showNewGroup = false;
    },

    async loadGroups() {
      const { uid } = this.$store.state.currentUserInfo;
      this.groups = await this.getGroups(uid);
    },
  },
  mounted() {
    this.loadGroups();
  },
};
</script>

<style scoped>
.container {
  text-align: justify;
  padding: 4rem 1rem 0rem 4.5rem;
}

.dialogActionBtns {
  display: flex;
  justify-content: space-between;
}
</style>
